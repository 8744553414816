// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  ion-content {
    --background : #1E1F20;
    --color: white;
  }
  button.enter{
    background: var(--ion-color-success, #2dd36f) !important;
    color:white;
  }
  .text-red {
    color : #FF4B4B;
  }
  .font-weight-light {
    font-family : 'open_sanslight';
  }
  .btn-arrow {
    background : url(/assets/arrow.svg) no-repeat;
    width      : 32px;
    height     : 12px;
  }
  //body {
  //  margin-top: env(safe-area-inset-top);
  //  margin-top: constant(safe-area-inset-top);
  //}
  //
  //ion-tab-bar {
  //  margin-bottom: env(safe-area-inset-bottom);
  //}

  --ion-font-family                  : 'open_sansbold';
  --ion-text-color                   : #0D1A37;

  --ion-color-darkblack              : #0D1A37;
  --ion-color-bgblue                 : #1f3d7d;
  --ion-color-transparent            : transparent;

  --ion-color-primary                : #1E1F20;
  --ion-color-primary-rgb            : 23, 42, 83;
  --ion-color-primary-contrast       : #ffffff;
  --ion-color-primary-contrast-rgb   : 255, 255, 255;
  --ion-color-primary-shade          : #142549;
  --ion-color-primary-tint           : #2e3f64;

  --ion-color-secondary              : #4A81CD;
  --ion-color-secondary-rgb          : 74, 129, 205;
  --ion-color-secondary-contrast     : #ffffff;
  --ion-color-secondary-contrast-rgb : 255, 255, 255;
  --ion-color-secondary-shade        : #4172b4;
  --ion-color-secondary-tint         : #5c8ed2;

  --ion-color-tertiary               : #5EA4FF;
  --ion-color-tertiary-rgb           : 94, 164, 255;
  --ion-color-tertiary-contrast      : #000000;
  --ion-color-tertiary-contrast-rgb  : 0, 0, 0;
  --ion-color-tertiary-shade         : #5390e0;
  --ion-color-tertiary-tint          : #6eadff;

  --ion-color-success                : #2dd36f;
  --ion-color-success-rgb            : 45, 211, 111;
  --ion-color-success-contrast       : #ffffff;
  --ion-color-success-contrast-rgb   : 255, 255, 255;
  --ion-color-success-shade          : #28ba62;
  --ion-color-success-tint           : #42d77d;

  --ion-color-warning                : #ffc409;
  --ion-color-warning-rgb            : 255, 196, 9;
  --ion-color-warning-contrast       : #000000;
  --ion-color-warning-contrast-rgb   : 0, 0, 0;
  --ion-color-warning-shade          : #e0ac08;
  --ion-color-warning-tint           : #ffca22;

  --ion-color-danger                 : #FF5C5C;
  --ion-color-danger-rgb             : 255, 92, 92;
  --ion-color-danger-contrast        : #000000;
  --ion-color-danger-contrast-rgb    : 0, 0, 0;
  --ion-color-danger-shade           : #e05151;
  --ion-color-danger-tint            : #ff6c6c;

  --ion-color-dark                   : #222428;
  --ion-color-dark-rgb               : 34, 36, 40;
  --ion-color-dark-contrast          : #ffffff;
  --ion-color-dark-contrast-rgb      : 255, 255, 255;
  --ion-color-dark-shade             : #1e2023;
  --ion-color-dark-tint              : #383a3e;

  --ion-color-medium                 : #92949c;
  --ion-color-medium-rgb             : 146, 148, 156;
  --ion-color-medium-contrast        : #ffffff;
  --ion-color-medium-contrast-rgb    : 255, 255, 255;
  --ion-color-medium-shade           : #808289;
  --ion-color-medium-tint            : #9d9fa6;

  --ion-color-light                  : #ffffff;
  --ion-color-light-rgb              : 255, 255, 255;
  --ion-color-light-contrast         : #000000;
  --ion-color-light-contrast-rgb     : 0, 0, 0;
  --ion-color-light-shade            : #e0e0e0;
  --ion-color-light-tint             : #ffffff;
}

.ion-color-white {
  --ion-color-base         : var(--ion-color-light) !important;
  --ion-color-base-rgb     : var(--ion-color-light-rgb) !important;
  --ion-color-contrast     : var(--ion-color-light-contrast) !important;
  --ion-color-contrast-rgb : var(--ion-color-light-contrast-rgb) !important;
  --ion-color-shade        : var(--ion-color-light-shade) !important;
  --ion-color-tint         : var(--ion-color-light-tint) !important;
}
.ion-color-darkblack {
  --ion-color-base : #0D1A37 !important;
}
.ion-color-transparent {
  --ion-color-base : var(--ion-color-transparent) !important;
  box-shadow       : none !important;
}
