@font-face {
    font-family: 'open_sansbold';
    src: url('opensans-bold-webfont.eot');
    src: url('opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-bold-webfont.woff2') format('woff2'),
         url('opensans-bold-webfont.woff') format('woff'),
         url('opensans-bold-webfont.ttf') format('truetype'),
         url('opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansextrabold';
    src: url('opensans-extrabold-webfont.eot');
    src: url('opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-extrabold-webfont.woff2') format('woff2'),
         url('opensans-extrabold-webfont.woff') format('woff'),
         url('opensans-extrabold-webfont.ttf') format('truetype'),
         url('opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sanslight';
    src: url('opensans-light-webfont.eot');
    src: url('opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-light-webfont.woff2') format('woff2'),
         url('opensans-light-webfont.woff') format('woff'),
         url('opensans-light-webfont.ttf') format('truetype'),
         url('opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sansregular';
    src: url('opensans-regular-webfont.eot');
    src: url('opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('opensans-regular-webfont.woff2') format('woff2'),
         url('opensans-regular-webfont.woff') format('woff'),
         url('opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
.sans-bold {font-family: 'open_sansbold', sans-serif;}
.sans-extrabold {font-family: 'open_sansextrabold', sans-serif;}
.sans-regular {font-family: 'open_sansregular', sans-serif;}
.sans-light {font-family: 'open_sanslight', sans-serif;}
