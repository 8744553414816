:root {
  @for $i from 6 through 100 {
    .fs-#{$i} {
      font-size : #{$i}px;
    }
  }
}
body {
  &.body-hide {
    background: transparent !important;
  }
}
.list-item {
  //width      : 100%;
  //background : white;
  //box-shadow : 20px 0px 20px rgb(0 0 0 / 15%);
  //margin     : 10px 0;
  //border     : 1px solid rgb(23 42 83 / 29%);
  //padding    : 15px;
}
