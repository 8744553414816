:root {
  img {
    &.progress {

    }
    &.loaded {
      animation : fadein 0.6s;
    }
  }

  .fader {
    position   : absolute;
    left       : 0px;
    width      : 100%;
    bottom     : 0px;
    height     : 100%;
    text-align : left;
    padding    : 15px 30px;
    z-index: 9;
    div {
      position  : absolute;
      bottom    : 10px;
      z-index   : 9;
      font-size : 1.5rem;
      left      : 0px;
      padding   : 5px 30px;
      @media only screen and (max-width : 512px) {
        font-size : 12px;
      }
    }
    &:after {
      content    : '';
      background : linear-gradient(360deg, rgba(0,0,0,0.8) 21.48%, rgba(0, 0, 0, 0) 97%);
      position   : absolute;
      bottom     : 0px;
      left       : calc(0px + 15px);
      width      : calc(100% - 30px);
      height     : 50%;
    }
  }
}
.img-100 {
  img {max-width: 100%;}
}
@keyframes fadein {
  from { opacity : 0; }
  to { opacity : 1; }
}
@-moz-keyframes fadein {
  from { opacity : 0; }
  to { opacity : 1; }
}
@-webkit-keyframes fadein {
  from { opacity : 0; }
  to { opacity : 1; }
}
@-ms-keyframes fadein {
  from { opacity : 0; }
  to { opacity : 1; }
}
