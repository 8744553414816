:root {
  p {
    font-size : 14px;
    @media only screen and (min-width : 992px) {
      font-size : 16px;
    }
  }
  h1 {font-size : 2.625rem;}
  h2 {font-size : 2.25rem;}
  h3 {font-size : 1.5rem;}
  h4 {font-size : 1.125rem;}
  h5 {font-size : 1rem;}
  h6 {font-size : 1rem;}
  @media only screen and (min-width : 1200px) {
    .container { max-width: 1500px; }
  }
}
