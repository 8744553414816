/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import '~ngx-progressive-image-loader/ngx-progressive-image-loader';

$scale      : 1;
$transition : opacity 0.2s linear;
@include progressive-image-loader($scale, $transition);
ngx-progressive-image-loader {
  .ngx-image-placeholder {
    height        : 100%;
  }
  .placeholder-loading-image {
    object-fit      : contain;
    object-position : center;
  }
  &.round {
    .ngx-image-placeholder {
      height        : inherit;
      border-radius : 50%;
    }
    .placeholder-loading-image {
      object-fit      : cover;
      object-position : center;
    }
  }
}
:root .btn-arrow {
  &:disabled {
    opacity: 0.1;
  }
}
